<template>
  <div>
    <v-layout row pa-2>
      <v-flex
        xs12
        sm6
        lg4
        xl3
        pr-4
        v-for="(field, index) in fields"
        :key="index"
      >
        <component
          :is="field.type"
          :value="successMetricsValue(field.key)"
          :append-text="field.unit"
          :label="field.label"
          :disabled="field.isReadonly"
        />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { Main } from '@/services/modules';
import { get } from 'lodash';
import Number from '@/new-components/ui/NumberInput';

export default {
  name: 'Metrics',

  components: { Number },

  props: {
    schema: { type: Array, required: true },
    successMetrics: { type: Object, required: true },
  },
  data() {
    return {
      treatmentName: '',
      fields: [],
      rounded: [],
    };
  },
  mounted() {
    const { treatment } = this.$route.params;
    this.getTreatment(treatment);
    this.fields = this.schema;
  },

  methods: {
    async getTreatment(treatment) {
      try {
        const resp = await Main.getTreatment(treatment);
        this.treatmentName = get(resp, 'data.name', '');
      } catch (error) {
        this.setSnack({
          message: error.response.data.message,
          color: 'error',
        });
        this.$store.commit('snackbar/setSnack', {
          message: error.response.data.message,
          color: 'error',
        });
        if (error.response.data.code === 404) {
          this.$router.push({ name: 'Login' });
        }
      }
    },
    successMetricsValue(key) {
      return get(this.successMetrics, key);
    },
  },
};
</script>
