<template>
  <v-form v-model="formValid">
    <v-layout wrap>
      <v-flex xs12 sm4 px-2 pb-1 v-for="(field, index) in fields" :key="index">
        <div v-if="!field.hide">
          <PatientAge
            v-if="field.label === 'Age'"
            :years="patientAge.years"
            :days="patientAge.days"
          />
          <component
            v-else-if="field.type === 'interval' || field.key === 'duration'"
            v-model="doseDetails[field.key]"
            :is="field.type"
            :append-text="field.unit"
            :label="field.label"
            :disabled="field.isReadonly"
            :required="field.isRequired"
            :field-key="field.key"
            :items="field.items"
            :validate="validate"
            :allowUserToEditOrNot="allowUserToEditOrNot"
            
          />
          <component
            v-else-if="field.type === 'dropdown'"
            v-model="patientDetails[field.key]"
            :is="field.type"
            :append-text="field.unit"
            :label="field.label"
            :disabled="field.isReadonly"
            :required="field.isRequired"
            :field-key="field.key"
            :items="field.items"
            :validate="validate"
            :allowUserToEditOrNot="allowUserToEditOrNot"
          />
          <component
            v-else-if="field.type === 'radio'"
            v-model="patientDetails[field.key]"
            :is="field.type"
            :append-text="field.unit"
            :label="field.label"
            :disabled="field.isReadonly"
            :field-key="field.key"
            :items="field.items"
          />
          <component
            v-else
            v-model="patientDetails[field.key]"
            :is="field.type"
            :append-text="field.unit"
            :label="field.label"
            :allowUserToEditOrNot="allowUserToEditOrNot"
            :disabled="field.isReadonly || allowUserToEditOrNot"
            :required="field.isRequired"
            :field-key="field.key"
            :items="field.items"
            :min="field.min"
            :max="field.max"
            :max-date="today"
            :validate="validate"
            :message="field.message"
            :error-message="field.errorMessage"
            @change="onChange($event, field)"
            :commonFunction="commonFunction"
          />
        </div>
      </v-flex>
    </v-layout>
  </v-form>
</template>

<script>
import Number from "@/new-components/ui/NumberInput";
import Dropdown from "@/new-components/ui/Dropdown";
import Date from "@/new-components/ui/Datepicker";
import PatientAge from "@/new-components/patient/PatientAge";
import patientAgeMixin from "@/mixins/patientAgeMixin";
import Radio from "@/new-components/ui/Radio";
import patientValidateMixin from "@/mixins/patientValidateMixin";
import { get, orderBy } from "lodash";
import { DateTime } from "luxon";

export default {
  name: "PatientInformation",

  mixins: [patientAgeMixin, patientValidateMixin],

  components: {
    Number,
    Date,
    PatientAge,
    Radio,
    Dropdown,
  },

  props: {
    schema: { type: Array, required: true },
    existingDoseDetails: { type: Object, required: true },
    existingPatientDetails: { type: Object, required: true },
    allowUserToEditOrNot: { type: Boolean, required: false },
  },

  data() {
    return {
      today: DateTime.local().toISODate(),
      formValid: false,
      patientDetails: this.existingPatientDetails,
      doseDetails: this.existingDoseDetails,
      fields: [],
      validate: false,
      remoteValidation: {
        done: 0,
        total: 0,
      },
    };
  },

  watch: {
    schema(value) {
      const fields = get(value, "1.fields", []);
      this.fields = orderBy(fields, ["order"], ["asc"]);
      this.updateField("gestationalAge", "hide", true);
    },
    existingPatientDetails(value) {
      this.patientDetails = value;
    },
    existingDoseDetails(value) {
      this.doseDetails = value;
    },
    patientDetails: {
      deep: true,
      handler(value) {
        this.calcAge(value.birthday);
        this.$emit("update:existingPatientDetails", value);
      },
    },
    doseDetails: {
      deep: true,
      handler(value) {
        this.$emit("update:existingDoseDetails", value);
      },
    },
    isNeonate: {
      immediate: true,
      handler(value) {
        this.updateField("gestationalAge", "hide", !value);
      },
    },
    // validate() {
    //   const total = this.fields.filter((field) => field.remoteValidate).length;
    //   this.remoteValidation = {
    //     done: 0,
    //     total,
    //   };
    // },
  },

  methods: {
    async action(v, key) {
      const valid = v.fields.every(
        (field) =>
          this.patientDetails[field] != "" ||
          this.patientDetails[field] != undefined ||
          this.patientDetails[field] != null
      );
      if (valid) {
        this.commonFunction(v, key);
      }
    },
    async onChange(value, field) {
      if (value) {
        field.validator.map((valid) => {
          if (valid.type == "dependent") {
            valid.fields.map((field) => {
              this.fields.map((f) => {
                if (f.key == field) {
                  f.validator.map((v) => {
                    if (v.type == "internal" || v.type == "external") {
                      this.action(v, field);
                    }
                  });
                }
              });
            });
          } else {
            if (valid.type == "external") {
              this.action(valid, field);
            }
            // console.log(valid)
          }
        });
      }
      // if (field.remoteValidate) {
      // const payload = {
      //   field: field.key,
      //   value,
      //   params: {
      //     age: this.patientAge.years,
      //     gender: this.patientDetails.gender,
      //   },
      // };
      // await this.remoteValidate(
      //   field.key,
      //   '/patient/validate',
      //   payload
      // );
      // const status = get(data, "status", "ok");
      // const message = get(data, "message", "");
      // if (status === "error") {
      //   this.updateField(field.key, "errorMessage", "");
      //   // this.updateField(field.key, "errorMessage", message);
      //   this.updateField(field.key, "message", "");
      // } else {
      //   this.updateField(field.key, "errorMessage", "");
      //    this.updateField(field.key, "message", "");
      //   // this.updateField(field.key, "message", message);
      // }
      // this.remoteValidation.done++;
      // if (this.remoteValidation.done === this.remoteValidation.total) {
      //   this.$emit("validated");
      // }
      if (this.formValid) {
        // this.$emit("validated");
      }
      // }
    },
    commonFunction(v, key) {
      this.$emit("commonFunction", v, key);
    },
  },
};
</script>
<style>
/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
/* mozilla Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
