<template>
  <div class="py-3">
    <v-btn
      color="primary"
      outlined
      :disabled="allowUserToEditOrNot || editMode"
      @click="$emit('modifyDose')"
      >Modify Dose</v-btn
    >
    <div v-show="editMode" class="py-3">
      <div class="title">Dose Details</div>
      <DosageInfo
        is-suggested
        :general-details="generalDetails"
        :dose-details="suggestedDoseDetails"
        v-on="$listeners"
      />
      <v-form v-model="validDosage">
        <v-layout row pa-4>
          <v-flex
            xs12
            sm4
            lg4
            xl3
            pr-4
            v-for="(field, index) in allFields"
            :key="index"
          >
            <component
              v-if="
                field.key !== 'dosingregimen-weight' &&
                  field.key !== 'totaldailydose' &&
                  field.key !== 'totaldailydose-weight'
              "
              :items="field.items"
              v-model="doseDetails[field.key]"
              :is="field.type"
              :disabled="field.isReadonly"
              :validate="validate"
              :appendText="field.unit"
              :label="field.label"
            />
            <NumberInput
              v-else-if="field.key === 'dosingregimen-weight'"
              :is="field.type"
              :label="field.label"
              :appendText="field.unit"
              :value="doseByWeight(doseDetails.individualDose)"
              :disabled="field.isReadonly"
            />
            <NumberInput
              v-else-if="field.key === 'totaldailydose'"
              :is="field.type"
              :label="field.label"
              :appendText="field.unit"
              :required="field.isRequired"
              :disabled="field.isReadonly"
              :value="dailyDose"
            />
            <NumberInput
              v-else-if="field.key === 'totaldailydose-weight'"
              :is="field.type"
              :label="field.label"
              :appendText="field.unit"
              :required="field.isRequired"
              :value="doseByWeight(dailyDose)"
              :disabled="field.isReadonly"
            />
          </v-flex>
        </v-layout>
      </v-form>
      <hr />
      <v-btn color="primary" :loading="saving" @click="onRecalculate"
        >Recalculate Metrics</v-btn
      >
    </div>
  </div>
</template>
<script>
import DosageInfo from "@/new-components/patient/DosageInfo";
import NumberInput from "@/new-components/ui/NumberInput";
import Dropdown from "@/new-components/ui/Dropdown";
import { get } from "lodash";
import Number from "@/new-components/ui/NumberInput";
export default {
  name: "ModifyDosage",
  components: { Dropdown, DosageInfo, NumberInput, Number },
  props: {
    schema: { type: Array, required: true },
    generalDetails: { type: Object, required: true },
    doseDetails: { type: Object, required: true },
    suggestedDoseDetails: { type: Object, required: true },
    enableNavigation: { type: Boolean, required: true },
    saving: { type: Boolean, required: true },
    allowUserToEditOrNot: { type: Boolean, required: true },
  },
  data() {
    return {
      validate: false,
      validDosage: true,
      allFields: [],
    };
  },
  computed: {
    editMode() {
      return !this.enableNavigation;
    },
    dailyDose() {
      return this.dosage(this.doseDetails);
    },
  },
  mounted() {
    this.allFields = this.schema;
    // this.fields = this.schema;
  },
  methods: {
    onRecalculate() {
      this.validate = true;
      this.$emit("recalculate", this.validDosage);
    },
    dosage(item) {
      const dose = get(item, "individualDose", 0);
      const interval = get(item, "interval", 0);
      return ((24 * dose) / interval).toFixed(0);
    },
    doseByWeight(dose) {
      const weight = get(this.generalDetails, "weight", 0);
      return (dose / weight).toFixed(0);
    },
  },
};
</script>
<style scoped>
hr {
  opacity: 0.1;
}
</style>
