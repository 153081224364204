<template>
  <v-select
    :id="id"
    placeholder="Select"
    :value="value"
    :items="items"
    :rules="applyRules"
    :error="isNotValid"
    :disabled="allowUserToEditOrNot"
    @input="$emit('input', $event)"
  >
    <span slot="label" v-html="label"></span>
    <span>{{ appendText }}</span>
  </v-select>
</template>

<script>
import validateInputMixin from "@/mixins/validateInputMixin";

export default {
  name: "Dropdown",

  mixins: [validateInputMixin],

  props: {
    allowUserToEditOrNot: { type: Boolean, required: false },
    items: { type: Array, required: true },
    fieldKey: { type: String },
    appendText: String,
  },
  mounted() {},
};
</script>

<style>
.v-list-item__content,
.v-select__selection {
  text-transform: capitalize !important;
}
</style>
