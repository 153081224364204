<template>
  <v-layout wrap column>
    <v-flex text-right>
      <v-btn
        v-if="mutable"
        v-show="button"
        color="success"
        @click="addItem"
        :disabled="allowUserToEditOrNot"
        >Add Event</v-btn
      >
      <EventEntry
        v-if="showEventEntry"
        :general-details="generalDetails"
        :edit-event="editEvent"
        @close="onCloseEventEntry"
      />
    </v-flex>
        <v-data-table :headers="headers" :items="events">
          <template v-slot:item.event="{ item }">
            <v-icon small>
              {{ item.type === "Level" ? "assessment" : "colorize" }}
            </v-icon>
            {{ item.event || item.type }}
          </template>
          <template v-slot:item.time="{ item }">
            {{ item.time | formatDate }} <br />
            at {{ item.time | formatTime }}
          </template>
          <template v-slot:item.value="{ item }">
            <EventValue
              :type="item.type"
              :values="item.values"
              :mutable="mutable"
              :allowUserToEditOrNot="allowUserToEditOrNot"
              @edit="onEditEvent(item)"
              @delete="onDeleteEvent(item)"
            />
          </template>
        </v-data-table>
  </v-layout>
</template>

<script>
import EventEntry from "@/new-components/patient/EventEntry";
import EventValue from "@/new-components/patient/EventValue";
import { DateTime } from "luxon";
import { get, cloneDeep } from "lodash";
import config from "../../config/instance";
import momentTz from "moment-timezone";

export default {
  name: "EventTable",

  components: { EventValue, EventEntry },

  props: {
    generalDetails: { type: Object, default: () => {} },
    existingEvents: { type: Array, required: true },
    mutable: { type: Boolean, default: true },
    allowUserToEditOrNot: { type: Boolean, required: false },
  },

  data() {
    return {
      button: true,
      isSelected: false,
      events: this.existingEvents,
      editIndex: -1,
      editEvent: null,
      showEventEntry: false,
      headers: [
        {
          text: "Event",
          value: "event",
          align: "left",
          sortable: false,
        },
        {
          text: "Time",
          value: "time",
          align: "right",
          sortable: false,
        },
        {
          text: "Value",
          value: "value",
          align: "right",
          sortable: false,
        },
      ],
    };
  },

  computed: {
    timezone() {
      return get(this.$config, "date_time.events.text", "");
    },
    changeSelectedEvent() {
      return this.editIndex;
    },
  },

  watch: {
    existingEvents(value) {
      this.events = value;
    },
    changeSelectedEvent(newValue, oldValue) {
      if (this.editIndex != -1) {
        let element = document.getElementsByTagName("tr");
        element[oldValue + 1].classList.remove("light-blue-lighten-4");
        element[newValue + 1].classList.add("light-blue-lighten-4");
      }
    },
    events: {
      deep: true,
      handler(value) {
        this.$emit("update:existingEvents", value);
      },
    },
  },

  mounted() {
    this.headers[1].text = this.timezone;
  },

  methods: {
    toggleEdit() {
      this.showEventEntry = false;
      this.editIndex = -1;
      this.editEvent = null;
    },
    addItem() {
      this.toggleEdit();
      this.showEventEntry = true;
    },
    onCloseEventEntry(event) {
      this.button = true;
      this.isSelected = false;
      let element = document.getElementsByTagName("tr");
      element[this.editIndex + 1].classList.remove("light-blue-lighten-4");
      if (event) {
      let utcDate = momentTz.tz(`${event.date}T${event.time}`, "YYYY-MM-DD HH:mm:ss", config.date_time.events.zone).toISOString();
      Object.assign(event, { time: Date.parse(new Date(utcDate))});
        if (this.editIndex > -1) {
          this.events.splice(this.editIndex, 1, event);
        } else {
          this.events.push(event);
        }
      }
      this.events.sort(function compare(a, b) {
        var dateA = new Date(a.time);
        var dateB = new Date(b.time);
        return dateA - dateB;
      });
      this.toggleEdit();
    },
    onDeleteEvent(event) {
      const index = this.events.indexOf(event);
      if (confirm("Are you sure you want to delete this item?")) {
        this.events.splice(index, 1);
      }
    },
    onEditEvent(event) {
      this.button=false;
      let index = this.events.indexOf(event);
      let element = document.getElementsByTagName("tr");
      element[index + 1].classList.add("light-blue-lighten-4");
      this.toggleEdit();
      this.editIndex = this.events.indexOf(event);
      const utcdateTime = DateTime.fromMillis(event.time);
      const dateTime = utcdateTime.setZone(config.date_time.events.zone);
      this.editEvent = {
        ...cloneDeep(event),
        date: dateTime.toISODate(),
        time: dateTime.toLocaleString(DateTime.TIME_24_SIMPLE),
        timestamp: event.time,
      };
      this.showEventEntry = true;
    },
  },
};
</script>
<style>
.light-blue-lighten-4 {
  background-color: rgb(201, 231, 238) !important;
}
</style>
