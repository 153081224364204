<template>
  <v-flex>
    <div class="title">Target Attainment</div>
    <v-layout row>
      <template v-for="field in fields">
        <component
          :is="field.type"
          :key="field.key"
          :suggested-metric="suggestedMetric(field.key)"
          :success-metric="successMetric(field.key)"
        >
          <template slot="label">
      <span v-html="field.label"></span>
          </template>
        </component>
      </template>
    </v-layout>
  </v-flex>
</template>
<script>
import Attainment from "@/new-components/patient/Attainment";
import { get } from "lodash";
// import schema from "../../assets/event";
export default {
  name: "TargetAttainment",
  data() {
    return {
      fields: []
    };
  },
  components: { Attainment },
  props: {
    schema: { type: Array, required: true },
    suggestedMetrics: { type: Object, required: true },
    successMetrics: { type: Object, required: true },
  },
  computed: {},
  mounted() {
    console.log(this.fields);
    this.fields = this.schema;
  },
  methods: {
    suggestedMetric(key) {
      return get(this.suggestedMetrics, key, 0);
    },
    successMetric(key) {
      return get(this.successMetrics, key, 0);
    }
  }
};
</script>