<template>
  <div>
    <Card title="Patient" :loading="loading">
      <PatientBasic
        ref="patientBasic"
        :new-patient="newPatient"
        :existing-patient-details.sync="generalDetails"
        :schema="schema"
        :allowUserToEditOrNot="allowUserToEditOrNot"
      />
    </Card>
    <Card title="Patient Information" :loading="loading">
      <PatientInformation
        ref="patientInformation"
        :existing-dose-details.sync="doseDetails"
        :existing-patient-details.sync="generalDetails"
        :patient-model="patientModel"
        :schema="schema"
        :allowUserToEditOrNot="allowUserToEditOrNot"
        @validated="onValidated"
        @change="onChange($event, field)"
        @commonFunction="commonFunction"
      />
    </Card>
    <Card title="Events" :loading="loading">
      <EventTable
        :general-details="generalDetails"
        :existing-events.sync="events"
        :patient-model="patientModel"
        :allowUserToEditOrNot="allowUserToEditOrNot"
      />
    </Card>
  </div>
</template>

<script>
import Card from "@/new-components/ui/Card";
import PatientBasic from "@/new-components/patient/PatientBasic";
import PatientInformation from "@/new-components/patient/PatientInformation";
import EventTable from "@/new-components/patient/EventTable";
import { Http } from "@/services/service";

export default {
  name: "PatientInformationTab",

  components: {
    Card,
    PatientBasic,
    PatientInformation,
    EventTable,
  },

  props: {
    newPatient: { type: Boolean, required: true },
    loading: { type: Boolean, required: true },
    patientModel: { type: String, default: "" },
    generalDetails: { type: Object, required: true },
    doseDetails: { type: Object, required: true },
    events: { type: Array, required: true },
    allowUserToEditOrNot: { type: Boolean, required: false },
  },
  created() {
    const { treatment } = this.$route.params;
    this.fetchSchema(treatment);
  },

  data() {
    return { schema: [] };
  },
  methods: {
    async fetchSchema(treatment) {
      try {
        const resp = await new Http({ auth: true }).get(
          `patient/getSchema/${treatment}`
        );
        if (resp.data) {
          if (resp.data.fieldParameters.length > 0) {
            this.schema = resp.data.fieldParameters[0].content;
          } else {
            this.$store.commit(
              "snackbar/setSnack",
              `Drug name ${resp.data.name} not yet supported`
            );
            this.$router.push({ name: "Condition" });
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    triggerValidate() {
      this.$refs.patientInformation.validate = false;
      this.$refs.patientBasic.validate = false;
      this.$nextTick(() => {
        this.$refs.patientInformation.validate = true;
        this.$refs.patientBasic.validate = true;
      });
    },
    onValidated() {
      const isValid =
        this.$refs.patientInformation.formValid &&
        this.$refs.patientBasic.formValid;
      this.$emit("valid", isValid);
    },
    commonFunction(v, key) {
      this.$emit("commonFunction", v, key);
    },
  },
};
</script>
