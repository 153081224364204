<template>
  <div>
    <Card title="Dose Recommendation" :loading="saving || loading">
      <DosageInfo
        v-if="!loading && schema"
        :general-details="patientDetails.generalDetails"
        :dose-details="patientDetails.dosedetails"
      />
      <ModifyDosage
        v-if="!loading && schema.length > 0"
        :general-details="patientDetails.generalDetails"
        :dose-details="patientDetails.dosedetails"
        :suggested-dose-details="patientDetails.suggested_dosedetails"
        :enable-navigation="enableNavigation"
        :saving="saving"
        :allowUserToEditOrNot="allowUserToEditOrNot"
        v-on="$listeners"
        :schema="_.find(schema, { type: 'modifyDose' }).fields"
        @commonFunction="commonFunction"
      />
      <TargetAttainment
        v-if="!loading && schema.length > 0"
        :suggested-metrics="patientDetails.suggestedSuccessMetrics"
        :success-metrics="patientDetails.successMetrics"
        :schema="_.find(schema, { type: 'metrics' }).fields[0].fields"
      />
      <hr />
      <Metrics
        v-if="!loading && schema.length > 0"
        :success-metrics="patientDetails.successMetrics"
        :pk-parameters="patientDetails.pkparameters"
        :schema="_.find(schema, { type: 'metrics' }).fields[1].fields"
      />
      <PKParameters
        v-if="!loading && schema.length > 0"
        :success-metrics="patientDetails.successMetrics"
        :pk-parameters="patientDetails.pkparameters"
        :schema="_.find(schema, { type: 'parameters' }).fields"
      />
    </Card>
  </div>
</template>

<script>
import Card from "@/new-components/ui/Card";
import DosageInfo from "@/new-components/patient/DosageInfo";
import ModifyDosage from "@/new-components/patient/ModifyDosage";
import TargetAttainment from "@/new-components/patient/TargetAttainment";
import PKParameters from "@/new-components/patient/PKParameters";
import Metrics from "@/new-components/patient/Metrics";
import _ from "lodash";
import { Http } from "@/services/service";

export default {
  name: "DoseRecommendationTab",

  components: {
    PKParameters,
    TargetAttainment,
    DosageInfo,
    ModifyDosage,
    Card,
    Metrics,
  },

  props: {
    loading: { type: Boolean, required: true },
    saving: { type: Boolean, required: true },
    enableNavigation: { type: Boolean, required: true },
    patientDetails: { type: Object, required: true },
    allowUserToEditOrNot: { type: Boolean, required: false },
  },
  mounted() {},
  data() {
    return { schema: [] };
  },
  created() {
    const { treatment } = this.$route.params;
    this.fetchSchema(treatment);
  },
  computed: {
    _() {
      return _;
    },
  },
  methods: {
    async fetchSchema(treatment) {
      try {
        const resp = await new Http({ auth: true }).get(
          `patient/getSchema/${treatment}`
        );
        if (resp.data) {
          if (resp.data.fieldParameters.length > 0) {
            this.schema = resp.data.fieldParameters[1].content;
            // console.log(this.schema);
            var room = _.find(this.schema, { type: "metrics" });
            console.log(room);
          } else {
            this.$store.commit(
              "snackbar/setSnack",
              `Drug name ${resp.data.name} not yet supported`
            );
            this.$router.push({ name: "Condition" });
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    commonFunction(v, key) {
      this.$emit("commonFunction", v, key);
    },
  },
};
</script>

<style scoped>
hr {
  opacity: 0.1;
}
</style>
