TimePicker.Vue
<template>
  <v-menu
    ref="menu"
    v-model="menu2"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value="selectedTime"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ attrs }">
      <v-text-field
        v-mask="'##:##'"
        v-model="selectedTime"
        :rules="inputRules"
        prepend-icon="mdi-clock-time-four-outline"
        v-bind="attrs" 
        label="Time (24 Hours)"
      ></v-text-field>
    </template>
    <!-- <v-time-picker
      color="#EF515B"
      v-if="menu2"
      no-title
      format="24hr"
      v-model="selectedTime"
      full-width
      @click:minute="$refs.menu.save(selectedTime)"
    ></v-time-picker> -->
  </v-menu>
</template>
<script>
import { mask } from 'vue-the-mask';
export default {
  name: 'Timepicker',
  directives: {
    mask,
  },
  props: {
    value: null,
  },
  data() {
    return {
      inputRules: [
        (v) => v.length > 4 || 'Required',
        (v) =>
          /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'Enter a valid time',
      ],
      menu2: false,
      modal2: false,
      selectedTime: this.value,
    };
  },
  watch: {
     value: {
      immediate: true,
      handler(value) {
        this.selectedTime = value;
      },
    },
    selectedTime(value) {
      this.$emit('input', value);
    },
  },
};
</script>
<style></style>
